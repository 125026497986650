<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/lessonschedule/camp' }"
          >训练营列表</el-breadcrumb-item
        >
        <el-breadcrumb-item>训练营查看</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!--内容-->
    <div class="page-content-x">
      <!--标题-->
      <el-row>
        <el-col :span="12"
          ><h3>{{ page_name }}</h3></el-col
        >
      </el-row>
      <div style="height: 20px"></div>
      <!--详情-->
      <div>
        <div style="height: 20px"></div>
        <!--详情-->
        <el-form
          class="time-arrange-form"
          v-loading="loading"
          size="medium"
          :label-width="this.env.label_width"
        >
          <el-row>
            <el-col :span="16">
              <el-form-item label="企业名称">
                <span>{{ info.company_name }}</span>
              </el-form-item>
              <el-form-item label="上课门店">
                <span>{{ info.store_name }}</span>
              </el-form-item>
              <el-form-item label="上课教室">
                <span>{{ info.classroom_name }}</span>
              </el-form-item>
              <el-form-item label="上课教练">
                <span>{{
                  info.coach_user_nick ? info.coach_user_nick : "当前课程没有教练"
                }}</span>
              </el-form-item>
              <el-form-item label="上课课程名">
                <span>{{ info.lesson_name }}</span>
              </el-form-item>
              <el-form-item label="教室可容纳人数">
                <span>{{ info.capacity }}人/{{ info.area }}平</span>
              </el-form-item>
              <el-form-item label="人数上限">
                <span>{{ info.quota }}</span>
                <span v-if="info.bookings_sum">已预约:{{ info.bookings_sum }}人</span>
              </el-form-item>
              <el-form-item label="最少开课人数">
                <span>{{ info.bookings_min }}</span>
              </el-form-item>
              <el-form-item label="提前N小时确定">
                <span
                  >开课前{{
                    info.determine_time
                  }}小时确定是否达到最小开课人数。未达到则自动取消。0不设置</span
                >
              </el-form-item>
              <el-form-item label="课程时长">
                <span>{{ info.lesson_duration }}分钟</span>
              </el-form-item>
              <el-form-item label="原价">
                <span>¥{{ info.originally_price }}</span>
              </el-form-item>
              <el-form-item label="预约价格">
                <span>¥{{ info.price }}</span>
              </el-form-item>
              <el-form-item label="课程数量">
                <span>{{ info.lesson_sum }}节</span>
              </el-form-item>
              <el-form-item label="开始时间">
                <div
                  class="lesson_time"
                  v-for="(item_lesson_time, index) in info.lesson_time"
                  :key="index"
                >
                  <span>{{ `第${index + 1}节 - ${item_lesson_time}` }}</span>
                </div>
              </el-form-item>

              <el-form-item label="排队等候">
                <span>{{
                  info.is_queue_up === 1 ? "允许排队等候" : "不允许排队等候"
                }}</span>
              </el-form-item>
              <el-form-item label="允许退款">
                <span>{{ info.is_refund === 1 ? "允许退款" : "不允许退款" }}</span>
              </el-form-item>
              <el-form-item label="上架时间(选填)">
                <span>{{ info.publish_time }}</span>
              </el-form-item>
              <el-form-item label="提示(选填)">
                <span>{{ info.tip ? info.tip : "暂无提示内容" }}</span>
              </el-form-item>
              <el-form-item label="适用范围">
                <span>{{ info.range === 1 ? "全部用户" : "新用户专享" }}</span>
              </el-form-item>
              <el-form-item>
                <el-button @click="back()" size="medium" type="primary">返回 </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page_name: "训练营查看",
      loading: false, // 加载状态

      info: {},
    };
  },
  // 创建
  created() {
    let good_uuid = this.$route.query.good_uuid;
    this.getInfo(good_uuid);
  },
  methods: {
    // 获取排课信息
    getInfo(good_uuid = "") {
      let postdata = {
        api_name: "lessonschedule.camp.getinfo",
        token: this.Tool.get_l_cache("token"),
        good_uuid,
      };
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.info = json.data;
        } else {
          this.Tool.errormes(json);
        }
      });
    },
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 返回
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*时间表*/
.time-arrange {
  /*border: #3a8ee6 1px solid;*/
  display: flex;
  justify-content: space-between;
  padding: 10px;
  box-shadow: 1px 1px 5px #d9d9d9;
  border-radius: 5px;
}

.font-size-mini {
  font-size: 12px;
}

/*课程表天*/
.timetable .time-arrange-column {
  width: 100% !important;
  position: relative !important;
}

.timetable .time-tip {
  width: 100%;
  position: absolute;
}

.timetable .time-h {
  height: 60px;
}

.timetable .time-h-border {
  border-top: #ebeef5 1px solid;
}

.timetable .time-arrange-column-store-classroom {
  width: calc(100% - 45px);
  margin-left: 40px;
  position: absolute;
}

.time-arrange-list {
  /*margin-right: 20px;*/
  /*border: 1px solid #888888;*/
  /*border-radius: 5px;*/
  /*box-shadow: 1px 1px 5px #cccccc;*/
  padding: 0 5px;
}

/*小号字体*/
.text-se {
  font-size: 12px;
}

.time-arrange-column {
  display: flex;
  /*min-height: 960px;*/
  height: 1440px;
  width: 30px;
  border: 1px solid #ebeef5;
  margin-right: 5px;
  font-size: 12px;
  /*margin-left: 5px;*/
}

.time-arrange-column-type {
  position: relative;
  width: 50%;
}

/*门店时间占用*/
.time-arrange-column-store-classroom-card {
  border-radius: 5px;
  width: 100%;
  background: #ffa940;
  /*position: relative;*/
  position: absolute;
  /*溢出滚动条*/
  overflow-x: auto;
  /*溢出隐藏*/
  /*overflow: hidden;*/
}

/*隐藏滚动条*/
.time-arrange-column-store-classroom-card::-webkit-scrollbar {
  width: 0 !important;
}

/*教练时间占用*/

.time-arrange-form span {
  display: block;
  width: 100%;
  background-color: #f5f7fa;
  /* border: 1px solid #e4e7ed; */
  border-radius: 4px;
  font-size: 12px;
  padding: 0 15px;
  box-sizing: border-box;
  /*text-align: center;*/
}

.lesson_time {
  display: flex;
  margin-bottom: 10px;
}

.item_lesson_time {
  width: 80px !important;
}
</style>
